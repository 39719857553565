// staging でも、 NODE_ENV は production なので注意（staging は production の亜種）

const FLAVORS_CONST = ["production", "development", "staging"] as const;

const FLAVOR = process.env
  .GATSBY_FLAVOR as unknown as typeof FLAVORS_CONST[number];

if (!FLAVORS_CONST.includes(FLAVOR))
  throw new Error(`Invalid flavor: ${FLAVOR}`);

export const envs = {
  FLAVOR,
  STAGING: FLAVOR === "staging",
};
