import React from "react";
import { LOCAL_AREAS, LocalArea, LocalAreaKey } from "../../config/LocalArea";
import styled from "styled-components";
import { useAppUrls } from "../../hooks/urlHooks";

export default function DebugAreas() {
  const isDupKey =
    LOCAL_AREAS.length !== new Set(LOCAL_AREAS.map((area) => area.key)).size;
  const isDupTrackingId =
    LOCAL_AREAS.length !==
    new Set(LOCAL_AREAS.map((area) => area.trackingId)).size;

  if (isDupKey) return <div>Duplicate key exists</div>;
  if (isDupTrackingId) return <div>Duplicate trackingId exists</div>;

  return (
    <table style={{ overflow: "scroll" }}>
      {LOCAL_AREAS.map((area) => (
        <Tr area={area} key={area.key} />
      ))}
    </table>
  );
}

const PAGE_PATH_OVERRIDE_MAP: { [k in LocalAreaKey]?: string } = {
  "hotel-all": "hotel",
};

function Tr({ area }: { area: LocalArea }) {
  const values = Object.values(area);
  const appUrls = useAppUrls({ area });
  const pagePath = "/" + (PAGE_PATH_OVERRIDE_MAP[area.key] || area.key);
  return (
    <tr>
      <Td>
        <a href={pagePath}>{pagePath}</a>
      </Td>
      {values.map((value, i) => (
        <Td key={i}>
          {typeof value === "string" ? value : JSON.stringify(value)}
        </Td>
      ))}
      <Td>
        <a href={appUrls.qr.ios}>
          <img src={appUrls.qr.ios} height="30" />
        </a>
      </Td>
    </tr>
  );
}

const Td = styled.td`
  white-space: nowrap;
  border: solid #000 1px;
  padding: 4px;
`;
