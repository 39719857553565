const isBrowser = typeof window !== "undefined";

/**
 * window を直接使うと yarn build できないので、この関数でwindowを参照します。
 * 例： window.location.search を使う場合
 *    const searchString = getWindow(w => w.location.search) || ""
 */
export const getWindow = <T>(
  callback: (window: Window) => T
): T | undefined => {
  if (!isBrowser) return undefined;

  return callback(window);
};
